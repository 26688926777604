import $ from '../core/Dom';
import Viewport from '../core/Viewport';
import Dispatch from '../core/Dispatch';

import gsap from 'gsap';

import * as events from '../lib/events'

export default (el, props) => {
    const $el = $(el);
    const $logo = $el.find('[data-logo]');
    
    const $body = $('body');
    const $heroArea = $body.find('[data-hero-area]');
    const $footer = $body.find('#bottom');

    let tl = null;
    let isHidden = false;
    let hiddenThreshold = 80;
    let footerHideThreshold = 80;
    let currentScrollTop = Viewport.scrollTop;
    let isFrontpage = false;

    let footerOffsetY = 0;

    const init = () => {
        footerOffsetY = $footer.offset().top;
        isFrontpage = $body.hasClass('is-frontpage');
        
        if (isFrontpage && Viewport.scrollTop > $heroArea.height() - 100) {
            $body.addClass('js-disable-transistions');
            $body.addClass('is-logo-hidden');
            setTimeout(() => {
                $body.removeClass('js-disable-transistions');
            }, 1);
        }
        
        gsap.set($logo.find('#final-o').nodes, { x: -60 });

        tl = gsap.timeline();
        tl.to($logo.find('#o').nodes, { duration: 0.1, x: -55, opacity: 0, ease: 'quint.in' }, 0.00);
        tl.to($logo.find('#g').nodes, { duration: 0.1, x: -50, opacity: 0, ease: 'quint.in' }, 0.03);
        tl.to($logo.find('#a').nodes, { duration: 0.1, x: -45, opacity: 0, ease: 'quint.in' }, 0.06);
        tl.to($logo.find('#r').nodes, { duration: 0.1, x: -40, opacity: 0, ease: 'quint.in' }, 0.09);
        tl.to($logo.find('#e').nodes, { duration: 0.1, x: -35, opacity: 0, ease: 'quint.in' }, 0.12);
        tl.to($logo.find('#t').nodes, { duration: 0.1, x: -30, opacity: 0, ease: 'quint.in' }, 0.15);
        tl.to($logo.find('#n').nodes, { duration: 0.1, x: -25, opacity: 0, ease: 'quint.in' }, 0.18);
        tl.to($logo.find('#final-o').nodes, { duration: 0.3, x: 0, opacity: 1, ease: 'quint.out' }, 0.25);
        tl.pause();

        $el.on('click', '[data-menu-toggle]', e => {
            e.preventDefault();
            Dispatch.emit(events.MENU_TOGGLE);
        });

        Viewport.on('resize', onResize);
        Viewport.on('scroll', onScroll);

        onResize();
    };


    const destroy = () => {
        Viewport.off('resize', onResize);
        Viewport.off('scroll', onScroll);
        $el.off('click');
        tl = null;
    };

    const hide = () => {
        if (isHidden) {
            return;
        }
        isHidden = true;
        $body.addClass('is-logo-hidden');
        tl.play();
    };

    const show = () => {
        if (!isHidden) {
            return;
        }
        
        isHidden = false;
        const { scrollTop } = Viewport;
        
        if (scrollTop < hiddenThreshold) {
            $body.removeClass('is-logo-hidden');
        }
        
        tl.reverse();
    };

    const onResize = () => {
        hiddenThreshold = isFrontpage && $heroArea.length > 0 ? $heroArea.height() - 100 : 80;
        footerOffsetY = $footer.offset().top;
        
        requestAnimationFrame(() => {
            const { scrollTop } = Viewport;
            onScroll(true);
        });
    };

    const onScroll = (force) => {
        const { scrollTop } = Viewport;
        if ((Math.abs(scrollTop - currentScrollTop) < 5) && !force) {
            return;
        }

        if (scrollTop < hiddenThreshold || (scrollTop > footerOffsetY - footerHideThreshold)) {
            show();
        } else {
            hide();
        }
        currentScrollTop = scrollTop;
    };

    return {
        init,
        destroy
    };
};
