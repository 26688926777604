import $ from '../core/Dom';
import gsap from 'gsap';

export default (el, props) => {
    const $el = $(el);
    const $links = $el.find('.hero-link-underline-red');
    
    let tl = null;

    const init = () => {
        tl = gsap.timeline({ repeat: 0 });
        
        $links.each((el, i) => {
            tl.call(($el) => {
                $el.attr('data-shown', 'true');
            }, [$(el)], 0.6 + (i * 0.6))
        });
    };

    const destroy = () => {
        if (tl) {
            tl.destroy();
        }
    };

    return {
        init,
        destroy
    };
};
