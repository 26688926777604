import $ from '../core/Dom';
import Viewport from '../core/Viewport';
import gsap from 'gsap';

export default (el, props) => {
    const $el = $(el);
    const $slash = $el.find('[data-quote-slash]');
    const $text = $el.find('[data-quote-text]');

    let observer = null;
    let slashStartX = 0;
    let textStartX = 0;
    let isAnimated = false;

    const init = () => {
        const vpWidth = Viewport.width;

        slashStartX = $slash.offset().left;
        textStartX = $text.offset().left;

        console.log(slashStartX, textStartX);

        observer = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                const { isIntersecting, intersectionRatio } = entry;
                if (!isIntersecting || intersectionRatio < 0.15) {
                    return;
                }
                onIntersect();
            });
        }, {
            threshold: [0.15]
        });

        observer.observe(el);

        Viewport.on('resize', onResize);

    };

    const onIntersect = () => {
        if (!isAnimated) {
            gsap.to($slash.get(0), { duration: 0.8, x: textStartX - slashStartX, ease: 'expo.inOut' });
            gsap.to($text.get(0), { duration: 0.8, x: textStartX - slashStartX, ease: 'expo.inOut' });

            isAnimated = true;
        }

        killObserver();
    };

    const killObserver = () => {
        if (observer) {
            observer.unobserve($el.get(0));
            observer = null;
        }
    };

    const onResize = e => {
        gsap.set([$slash.get(0), $text.get(0)], { x: 0 });

        slashStartX = $slash.offset().left;
        textStartX = $text.offset().left;

        if (isAnimated) {
            gsap.set([$slash.get(0), $text.get(0)], { x: textStartX - slashStartX });
        }
    };

    const destroy = () => {
        killObserver();
    };

    return {
        init,
        destroy
    };
};
