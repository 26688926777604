import $ from '../core/Dom';
import gsap from 'gsap';

export default (el, props) => {
    const $el = $(el);
    const $image = $el.find('[data-image]');

    let animationId = null;

    const start = () => {
        if (animationId !== null) {
            cancelAnimationFrame(animationId);
            animationId = null;
        }

        animationId = requestAnimationFrame(parallax);
    };

    const stop = () => {
        cancelAnimationFrame(animationId);
        animationId = null;
    };

    const parallax = () => {
        animationId = requestAnimationFrame(parallax);
        const scrollTop = (window.pageYOffset !== undefined) ? window.pageYOffset : (document.documentElement || document.body.parentNode || document.body).scrollTop;
        
        gsap.set($image.get(0), { y: scrollTop / 3 });
    };

    const init = () => {
        start();
    };

    const destroy = () => {
        stop();
    };

    return {
        init,
        destroy
    };
};
