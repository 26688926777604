import $ from '../core/Dom';
import Dispatch from '../core/Dispatch';

import gsap from 'gsap';

import * as events from '../lib/events'

export default (el, props) => {
    const $el = $(el);
    const $menuItems = $el.find('[data-menu-item]');
    const $menuToggle = $el.find('[data-menu-toggle]');
    const $companyInfo = $el.find('[data-company-info]');
    const $symbol = $el.find('[data-symbol]');
    const $bg = $el.find('[data-menu-bg]');

    let menuOpen = false;

    const openMenu = () => {
        $el.css({ height: '100%' });
        
        gsap.set($bg.nodes, { x: '100%', skewX: '-12deg', transformOrigin: 'left bottom' });
        gsap.set($menuItems.nodes, { x: '10%', opacity: 0 });
        gsap.set($menuToggle.nodes, { opacity: 0 });
        gsap.set($companyInfo.nodes, { opacity: 0 });
        gsap.set($symbol.nodes, { opacity: 0 });
        
        gsap.to($bg.nodes, { duration: 0.6, x: 0, ease: 'expo.out' });
        gsap.to($bg.nodes, { duration: 1, delay: 0.2, skewX: '0deg', ease: 'expo.out' });
        gsap.to($menuItems.nodes, { duration: 0.8, delay: 0.2, x: 0, ease: 'quint.out', stagger: { each: 0.04, from: 'end' } });
        gsap.to($menuItems.nodes, { duration: 0.8, delay: 0.2, opacity: 1, ease: 'sine.in', stagger: { each: 0.04, from: 'end' } });
        
        gsap.to($menuToggle.nodes, { duration: 0.5, delay: 0.6, opacity: 1, ease: 'sine.in' });
        gsap.to($companyInfo.nodes, { duration: 0.5, delay: 0.6, opacity: 1, ease: 'sine.in' });
        gsap.to($symbol.nodes, { duration: 0.5, delay: 0.6, opacity: 1, ease: 'sine.in' });
        
        menuOpen = true;
    };

    const closeMenu = () => {
        //$el.css({ height: '' });
        gsap.to([...$symbol.nodes, ...$menuItems.nodes, ...$menuToggle.nodes, ...$companyInfo.nodes], { duration: 0.05, opacity: 0, ease: 'none' });

        gsap.to($bg.nodes, { duration: 0.2, skewX: '-12deg', ease: 'Quint.out' });
        gsap.to($bg.nodes, { duration: 0.3, x: '100%', ease: 'sine.in', onComplete: () => {
            $el.css({ height: '' });
        } });

        menuOpen = false;
    };

    const onMenuToggle = () => {
        if (menuOpen) {
            closeMenu();
        } else {
            openMenu();
        }
    };

    const init = () => {
        Dispatch.on(events.MENU_TOGGLE, onMenuToggle)
    };

    const destroy = () => {

    };

    return {
        init,
        destroy
    };
};
